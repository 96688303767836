:root {
  --arrow-size: 20px;
  --background: #f6f6f6;
  --theme: #fe9e0d;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Reem Kufi", sans-serif !important;
}
img {
  max-width: 100%;
  height: auto;
}
a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.54);
}
.App {
  min-height: 100vh;
  width: 85vw;
  max-width: 1900px;
  margin: 0rem auto;
}
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color: var(--background);
}
header {
  height: 90px;
}
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
}
nav a {
  color: black;
}
.scrolled {
  position: fixed;
  z-index: 99;
  width: 100vw;
  background-color: #dbdbdb;
  transition: all 1s ease;
  left: 0;
  padding: 0 7.5%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 0px 29px 0px;
}
.nav-logo-container {
  display: flex;
  align-items: center;
}
.nav-logo-container img {
  max-height: 75px;
}
.navbar-menu-container {
  display: none;
}
.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: black;
  font-size: 1.1rem;
  font-weight: 600;
}
.navbar-links-container a:hover {
  border-bottom: #e48f0f solid 3px;
  transition: all 0.05s ease-in-out;
}
.navbar-links-container .active {
  border-bottom: #e48f0f solid 3px;
}
.navbar-links-container .youtube-button:hover {
  border-bottom: none;
}
.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}
.primary-button:hover {
  background-color: rgb(234, 234, 234);
}
.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.home-banner-container {
  position: relative;
  display: flex;
  padding-top: 3rem;
}
.home-bannerImage-container {
  position: absolute;
  top: -100px;
  right: -7.5vw;
  z-index: -2;
  max-width: 700px;
  object-fit: cover;
}
.home-image-section {
  max-width: 600px;
  flex: 1;
}
.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.primary-heading {
  font-size: clamp(2rem, 5vw, 4rem);
  color: #4c4c4c;
  /* line-height: 5rem; */
  max-width: 600px;
}
.primary-text {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 500px;
  color: #6a6a6a;
  margin: 1.5rem 0rem;
}
.secondary-button {
  padding: 1rem 2.5rem;
  background-color: var(--theme);
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}
.secondary-button:hover {
  background-color: #e48f0f;
}

/* youtube section */

.youtube-section-container {
  margin-top: 20rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.youtube-background-image-container {
  position: absolute;
  left: -150px;
  z-index: -2;
}
.youtube-section-image-container {
  flex: 0.9;
  margin-right: 1rem;
  aspect-ratio: 640/360;
  margin-right: 3rem;
  min-width: 420px;
}
.youtube-section-text-container {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.primary-subheading {
  font-weight: 700;
  color: var(--theme);
  font-size: 1.15rem;
}
.youtube-buttons-container {
  margin-top: 2rem;
  display: flex;
}
.watch-video-button {
  margin-left: 2rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: #484848;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.watch-video-button svg {
  font-size: 3rem;
  margin-right: 1rem;
}

/* footer */

footer {
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: 10rem;
}
.footer-wrapper {
  background-color: var(--theme);
  padding: 6rem 12vw;
  display: flex;
}
.footer-logo-container {
  max-width: 110px;
}
.footer-icons {
  margin-top: 2.5rem;
}
.footer-icons svg {
  font-size: 1.5rem;
  margin-right: 1.25rem;
  color: #585858;
}
/* .footer-section-one {
  border: 2px solid blue;
} */
.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.footer-section-columns {
  display: flex;
  flex-direction: column;
  min-width: 190px;
  margin-left: 2rem;
}
.footer-section-columns span {
  margin: 0.25rem 0rem;
  font-weight: 600;
  color: #4c4c4c;
  cursor: pointer;
}

/* people slider */

.people-slider-section-bottom {
  margin: 2rem auto;
  background-color: white;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 750px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
}

.people-slider-section-bottom .read-more-button {
  margin-top: 0;
}
.people-slider-section-bottom p {
  font-weight: 700;
  color: #515151;
  max-width: 500px;
  font-size: 1.1rem;
  margin: 2rem 0rem;
}
.people-slider-section-bottom h2 {
  margin-top: 1.5rem;
}

/* people list */

.people-list-wrapper {
  margin-top: 7rem;
}
.people-list-top p {
  text-align: center;
  max-width: 600px !important;
}
.people-list-top h1 {
  max-width: 700px !important;
}
.people-list-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.people-list-info {
  width: 290px;
  min-height: 350px;
  background-color: white;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #505050;
  margin: 1rem 2rem;
}
.people-list-info h2 {
  margin: 1rem 0rem;
}
.people-list-bottom {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.people-list-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 600;
}
.people-list-info img {
  border-radius: 1rem;
  min-width: 226px;
  /* #TODO remove min-width and add high resolution images */
}
.people-list-background-image-container {
  position: absolute;
  left: -350px;
  z-index: -2;
  width: 800px;
  top: -500px;
}

.peolpe-list-background-container2 {
  position: absolute;
  top: -100px;
  right: 0;
  z-index: -2;
  max-width: 600px;
  height: 800px;
}
.peolpe-list-background-container2 img {
  object-fit: cover;
  object-position: 0 100%;
  height: 800px;
}

.people-list-background-image-container-middle {
  position: absolute;
  z-index: -2;
  left: -200px;
}
@media (max-width: 1200px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .people-list-background-image-container {
    display: none;
  }
  .youtube-buttons-container {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }
  .youtube-buttons-container .watch-video-button {
    margin-left: 0;
  }
}
@media (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .primary-button {
    font-size: 1rem;
  }
  .home-bannerImage-container {
    max-width: 600px;
  }
  .people-list-background-image-container {
    display: none;
  }
}
@media (max-width: 800px) {
  .nav-logo-container {
    max-width: 140px;
  }
  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: flex;
  }
  .home-bannerImage-container {
    max-width: 450px;
  }
  .home-banner-container,
  .youtube-section-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .youtube-section-container {
    flex-direction: column;
  }
  .youtube-section-image-container {
    width: 100%;
    margin-right: 0;
  }
  .home-image-section {
    width: 100%;
    max-width: 400px;
  }
  .primary-heading {
    text-align: center;
    max-width: 90%;
  }
  .primary-text {
    text-align: center;
    max-width: 80%;
  }
  .home-text-section,
  .youtube-section-text-container {
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }
  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }
  .youtube-section-container {
    margin-top: 5rem !important;
  }
  .youtube-buttons-container {
    justify-content: center;
    flex-direction: column;
  }
  .primary-subheading {
    text-align: center;
  }
  .watch-video-button {
    margin-left: 0rem !important;
    margin-top: 1rem;
    font-size: 1rem !important;
  }
  .watch-video-button svg {
    margin-right: 0.5rem !important;
  }
  .youtube-section-image-container {
    margin-right: 0rem !important;
  }
  .people-list-wrapper {
    margin-top: 5rem !important;
  }
  .people-list-bottom {
    margin-top: 1rem !important;
  }
  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;
  }
  .contact-form-container input {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }
  .footer-wrapper {
    flex-direction: column;
  }
  .footer-section-two {
    flex-direction: column;
    margin-top: 2rem;
  }
  .footer-section-columns {
    margin: 1rem 0rem;
  }
  .App {
    max-width: 95vw;
  }
  .youtube-section-image-container {
    min-width: auto;
  }
}
@media (max-width: 600px) {
  .home-bannerImage-container,
  .youtube-background-image-container {
    display: none;
  }
}

.carousel-root {
  display: flex;
  justify-content: center;
}

.carousel.carousel-slider {
  width: 80% !important;
}

.carousel .control-dots {
  bottom: -1rem !important;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: var(--theme) !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: transparent !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

.carousel.carousel-slider .control-prev::before {
  border-right: var(--arrow-size) solid var(--theme) !important;
}

.carousel.carousel-slider .control-next::before {
  border-left: var(--arrow-size) solid var(--theme) !important;
}

.carousel.carousel-slider .control-arrow::before {
  border-top: var(--arrow-size) solid transparent !important;
  border-bottom: var(--arrow-size) solid transparent !important;
}

.carousel img {
  width: 90px !important;
  height: 120px !important;
  border-radius: 1rem;
}

.custom-shape-divider-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom svg {
  position: relative;
  display: block;
  width: calc(201% + 1.3px);
  height: 158px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom .shape-fill {
  fill: #fe9e0d;
}
.a-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.read-more-button {
  padding: 0.5rem 1.5rem;
  margin-top: 1.5rem;
  background-color: var(--theme);
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.read-more-button:hover {
  background-color: #e48f0f;
}

/* person modal */

.modal {
  inset: 0;
  position: fixed;
  z-index: 100;
}
.modal-background {
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  position: fixed;
}

.person-modal {
  width: 80vw;
  height: 40vw;
  border-radius: 1rem;
  background-color: var(--background);
  box-shadow: rgba(0, 0, 0, 0, 35) 0 5px 15px;
  left: 50%;
  bottom: 50%;
  position: absolute;
  transform: translate(-50%, 50%);
  padding: 2rem;
}
.person-modal-content {
  display: flex;
  height: 100%;
}

.person-modal button {
  height: 2rem;
  border: none;
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.person-modal button svg {
  width: 2rem;
  height: inherit;
}

.person-modal-image-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-height: calc(40vw - 4rem);
  aspect-ratio: 3/4;
}

.person-modal-image-section img {
  height: 100%;
  display: block;
  border-radius: 1rem;
}

.person-modal-description {
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.person-modal-description .primary-text {
  max-width: none;
  overflow-y: auto;
}
.person-modal-name-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 1rem;
}
.person-modal-name-section .primary-heading {
  max-width: 100%;
}

.person-modal-next-button,
.person-modal-prev-button {
  position: fixed;
  z-index: 100;
  cursor: pointer;
  font-size: 2.5rem;
  top: 50%;
}

.person-modal-next-button {
  right: 2rem;
}

.person-modal-prev-button {
  left: 2rem;
}

@media (max-width: 1600px) {
  .person-modal-description .primary-heading {
    font-size: 2rem;
  }
  .person-modal-description p {
    font-size: 20px;
  }
}
@media (max-width: 1400px) {
  .peolpe-list-background-container2 {
    max-width: 400px;
  }
}

@media (max-width: 1000px) {
}

@media (max-width: 1000px) {
  .peolpe-list-background-container2 {
    max-width: 300px;
  }
  .person-modal {
    height: fit-content;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
  .person-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: fit-content;
  }
  .person-modal-image-section {
    max-height: calc(30vw - 4rem);
  }
  .person-modal-description {
    padding: 0;
    max-height: none;
  }
  .person-modal-description .primary-text {
    text-align: left;
  }
  .person-modal-name-section .primary-heading {
    text-align: left;
  }
  .person-modal-name-section .primary-subheading {
    text-align: left;
  }
}

@media (max-width: 800px) {
  .person-modal-image-section {
    max-height: calc(50vw - 4rem);
  }
  .peolpe-list-background-container2 {
    display: none;
  }
  .person-modal-next-button {
    right: 1rem;
  }

  .person-modal-prev-button {
    left: 1rem;
  }
}

@media (max-width: 600px) {
  .person-modal-name-section .primary-heading {
    font-size: 4.5vw;
  }
  .person-modal-next-button,
  .person-modal-prev-button {
    top: unset;
    bottom: 10%;
  }

  .person-modal-next-button {
    right: 30%;
  }

  .person-modal-prev-button {
    left: 30%;
  }
  .person-modal-description {
    margin-top: 1rem;
    max-height: 60vw;
    overflow-y: auto;
  }
}

@media (max-width: 500px) {
  .person-modal-name-section .primary-subheading {
    font-size: 4vw;
  }
}
/* films */

.film-list-wrapper {
  margin-top: 7rem;
}
.film-list-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.film-list-background-container {
  position: absolute;
  top: -200px;
  left: -170px;
  z-index: -2;
  width: 600px;
}
.film-list-background-container img {
  width: 100%;
}
.film-list-background-container2 {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: -2;
  width: 550px;
}
.film-list-background-container2 img {
  width: 100%;
}
.film-background-image-container-middle {
  position: absolute;
  top: 60rem;
  left: -40rem;
  z-index: -2;
}
.film-list-top p {
  text-align: center;
  max-width: 600px !important;
}
.film-list-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 7rem;
  gap: 4rem;
}
.film-list-box {
  background-color: white;
  padding: 2rem;
  width: 80vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  border-radius: 1rem;
}
.film-list-box-odd {
  background-color: #e4e4e4;
  padding: 2rem;
  width: 80vw;
  display: grid;
  direction: rtl;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  border-radius: 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.film-list-player {
  aspect-ratio: 640/360;
  width: calc(40vw - 2rem);
}
.film-list-description {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  padding-left: 2rem;
}
.film-list-box-odd .film-list-description {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: left;
  padding-right: 2rem;
}
.film-list-box .watch-video-button svg {
  margin-right: 0;
  margin-left: 1rem;
}
.film-list-description .primary-text {
  max-width: none;
}
.film-list-box-odd .watch-video-button {
  margin-left: 0;
}

.film-list-wrapper {
  margin-top: 7rem;
}
.film-list-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.film-list-top p {
  text-align: center;
  max-width: 600px !important;
}
.film-list-bottom {
  display: grid;
  flex-direction: column;
  margin-top: 7rem;
  gap: 4rem;
}
.film-list-box {
  background-color: white;
  padding: 2rem;
  width: 80vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  border-radius: 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.film-list-box-odd {
  background-color: #e4e4e4;
  padding: 2rem;
  width: 80vw;
  display: grid;
  direction: rtl;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  border-radius: 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.film-list-player {
  aspect-ratio: 640/360;
  width: calc(40vw - 2rem);
}
.film-list-box-odd .film-list-description {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: left;
  padding-right: 2rem;
}
.film-list-box .watch-video-button svg {
  margin-right: 0;
  margin-left: 1rem;
}
.film-list-box-odd .watch-video-button {
  margin-left: 0;
}
@media (max-width: 1400px) {
  .film-list-background-container {
    width: 500px;
  }
  .film-list-background-container2 {
    width: 40vw;
  }
}
@media (max-width: 1200px) {
  .film-list-box,
  .film-list-box-odd {
    display: flex;
    flex-direction: column;
  }
  .film-list-box .watch-video-button {
    margin-left: 0;
  }
  .film-list-player {
    aspect-ratio: 640/360;
    width: 100%;
  }
  .film-list-description {
    padding: 0;
    margin-top: 2rem;
    align-items: flex-start;
    text-align: left;
  }
  .film-list-box-odd .film-list-description {
    padding-right: 0;
  }
  .film-list-description .primary-text {
    margin: 0.5rem 0;
  }
}
@media (max-width: 800px) {
  .film-list-description .primary-text {
    display: none;
  }
  .film-list-box,
  .film-list-box-odd {
    width: 100%;
    padding: 1.5rem;
  }
  .film-list-background-container,
  .film-list-background-container2,
  .film-background-image-container-middle {
    display: none;
  }
}

/* music */
.music-list-wrapper {
  margin-top: 7rem;
}
.music-list-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.music-list-top p {
  text-align: center;
  max-width: 600px !important;
}
.music-list-background-container {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: -2;
  width: 700px;
}
.music-list-background-container img {
  width: 100%;
}
.music-list-background-container2 {
  position: absolute;
  top: -500px;
  left: -100px;
  z-index: -2;
  width: 700px;
}
.music-list-background-container-middle {
  position: absolute;
  z-index: -2;
  top: 51vw;
  left: -150px;
  max-width: 320px;
}
.music-list-bottom {
  display: flex;
  flex-direction: column;
  margin-top: 7rem;
  gap: 4rem;
}
.music-list-box {
  background-color: white;
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 1rem;
}
.music-list-player {
  aspect-ratio: 640/360;
  width: 100%;
}
.music-list-description {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  margin-top: 1rem;
}
.music-list-box .watch-video-button {
  margin-left: 0;
}
.music-list-box .watch-video-button svg {
  margin-right: 0;
  margin-left: 1rem;
}
.music-list-description .primary-text {
  max-width: none;
}
@media (max-width: 1600px) {
  .music-list-background-container {
    width: 45vw;
  }
  .music-list-background-container2 {
    width: 45vw;
  }
}
@media (max-width: 1400px) {
  .music-list-background-container2 {
    display: none;
  }
}
@media (max-width: 1000px) {
  .music-list-description {
    flex-direction: column;
  }
  .music-list-description .primary-subheading {
    text-align: left;
  }
}
@media (max-width: 800px) {
  .music-list-background-container,
  .music-list-background-container-middle {
    display: none;
  }
}

.inside-text-link {
  color: var(--theme);
}

.inside-text-link:hover {
  color: #e48f0f;
  transition: 0.2s ease-in;
}

/* about main section */

.about-main-section-wrapper {
  margin-top: 3rem;
}
.about-main-background {
  position: absolute;
  z-index: -1;
  right: 0;
  top: 0;
}
.about-main-section {
  display: flex;
}
.about-main-section-description {
  flex: 1;
}
.about-main-section-description .primary-text {
  max-width: none;
  padding-right: 3rem;
}
.about-main-section-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-main-section-image img {
  border-radius: 1rem;
}

@media (max-width: 1400px) {
  .about-main-background {
    max-width: 600px;
  }
}

@media (max-width: 1200px) {
  .about-main-background {
    max-width: 500px;
    top: -200px;
  }
  .about-main-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .about-main-section-description {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about-main-section-description .primary-text {
    max-width: 80%;
    text-align: center;
    padding-right: 0;
  }
}

@media (max-width: 800px) {
  .about-main-background {
    display: none;
  }
}

/* about history section */

.about-history-section-wrapper {
  margin-top: 15rem;
}
.about-history-section {
  display: flex;
}
.about-history-background {
  position: absolute;
  top: 700px;
  left: -500px;
  z-index: -1;
}
.about-history-section-description {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  flex: 1;
}
.about-history-section-description .primary-text {
  max-width: none;
  padding-left: 3rem;
}
.about-history-section-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-history-section-image img {
  border-radius: 1rem;
}

@media (max-width: 1200px) {
  .about-history-section-wrapper {
    margin-top: 6rem;
  }
  .about-history-background {
    top: 1200px;
    width: 800px;
  }
  .about-history-section {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .about-history-section-description {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about-history-section-description .primary-text {
    max-width: 80%;
    text-align: center;
    padding-left: 0;
  }
}

@media (max-width: 800px) {
  .about-history-background {
    display: none;
  }
}

/* gallery */

.gallery-section-wrapper {
  margin-top: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
}

.gallery-background {
  position: absolute;
  right: 0;
  top: 100rem;
  z-index: -1;
}

.gallery-section-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.gallery-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.gallery-images {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.gallery-wrapper .gallery-single {
  width: 300px;
  height: 225px;
  cursor: pointer;
}

.gallery-wrapper .gallery-single img {
  width: 100%;
  height: 100%;
  object-position: 50% 50%;
  object-fit: cover;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 0px 29px 5px;
}

.gallery-wrapper .gallery-single img:hover {
  transform: scale(1.02);
  transition: 0.2s ease-in;
}

.gallery-slider-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.54);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.gallery-fullscreen-single {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 70%;
}

.gallery-fullscreen-single img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.gallery-close-button,
.gallery-next-button,
.gallery-prev-button {
  position: fixed;
  z-index: 100;
  cursor: pointer;
  opacity: 0.7;
}

.gallery-close-button {
  right: 2rem;
  top: 2rem;
  font-size: 1.5rem;
}

.gallery-next-button {
  right: 4rem;
  top: 50%;
  font-size: 2rem;
}

.gallery-prev-button {
  left: 4rem;
  top: 50%;
  font-size: 2rem;
}

@media (max-width: 1200px) {
  .gallery-background {
    top: 150rem;
  }
}

@media (max-width: 800px) {
  .gallery-background {
    display: none;
  }

  .gallery-next-button {
    right: 1rem;
  }

  .gallery-prev-button {
    left: 1rem;
  }
}

/* Login */

.login {
  margin-top: 10rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.login .background {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: -1;
}

.login .background img {
  width: 100%;
}

.login .login-form {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: white;
  padding: 3rem;
  border-radius: 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.login .button-container input {
  width: 100%;
  font-size: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.login .title-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.login .title-section .image-container {
  display: flex;
  justify-content: center;
}

.login .title-section .primary-heading {
  max-width: 100% !important;
  color: white;
}

.login .title-section img {
  max-height: 100px;
  max-width: 100px;
}

.login .title-section * {
  z-index: 2;
}

.login .title-section .background-blob {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  border-radius: 1rem 1rem 0 0;
  width: 100%;
  height: 275px;
  background-color: var(--theme);
  animation: blob-animation 5s ease-in-out infinite;
  transition: all 1s ease-in-out;
}

@keyframes blob-animation {
  0% {
    border-radius: 1rem 1rem 30% 70%/1rem 1rem 70% 40%;
  }
  50% {
    border-radius: 1rem 1rem 70% 40%/1rem 1rem 30% 60%;
  }
  100% {
    border-radius: 1rem 1rem 30% 70%/1rem 1rem 70% 40%;
  }
}

@media (max-width: 600px) {
  .login .title-section .background-blob {
    height: 235px;
  }
  .login .background {
    display: none;
  }
  .login {
    margin-top: 4rem;
  }
  .login .login-form {
    position: relative;
    width: 100vw;
  }
}

.login .input-container {
  position: relative;
  border-bottom: black solid 2px;
  margin-bottom: 1rem;
}

.login .input-container input {
  width: 100%;
  padding: 0 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
}

.login .input-container label {
  position: absolute;
  top: 50%;
  left: 5px;
  font-size: 16px;
  transform: translateY(-50%);
  pointer-events: none;
  transition: 0.5s;
}

.login .input-container span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #6a6a6a;
}

.login .input-container input:focus ~ label,
.login .input-container input:valid ~ label {
  top: -5px;
  color: var(--theme);
}

.login .input-container input:focus ~ span,
.login .input-container input:valid ~ span {
  width: 100%;
}

.error {
  text-align: center;
  color: #d8000c;
}

.loading-container {
  display: flex;
  justify-content: center;
}

.game-button button {
  position: absolute;
  top: calc(40vw - 4rem);
  right: 2rem;
}

.world {
  display: none;
}

.go-back-button {
  display: none;
  position: absolute;
  z-index: 100;
  top: 2rem;
  left: 2rem;
}

.disabled-game-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin-top: 20rem;
  margin-bottom: 20rem;
  padding: 1rem;
}

.disabled-game-message {
  text-align: center;
}

.game-loading-page {
  display: flex;
  justify-content: center;
  margin-top: 13rem;
  margin-bottom: 3rem;
}
